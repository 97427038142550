<template>
  <div class="index-box">
    <div class="head">
      <div>
        深圳市八厘米科技有限公司
      </div>
      <div>
        Shenzhen Eight Centimeter Technology Co., Ltd.
      </div>
    </div>
    <div class="content">
      <div>
        <img src="../assets/images/logo_bg.png" alt="" class="logo-bg">
        <div class="title">做笔记、背书、背单词<br>
        </div>
        <div class="desc">
          速记卡一款将笔记、记忆和知识管理融合于一身的新型学习工具和教育工具，不只是记录，更重要的帮你吸收知识，闪记卡是通用型工具，不限于学英语、语文、计算机、法律、中西医等，在应试提分上有显著效果。
        </div>
      </div>
      <img src="../assets/images/logo.png" alt="" class="logo">
    </div>
    <div class="footer">
      <div>深圳市八厘米科技有限公司
      </div>
      <div>Shenzhen Eight Centimeter Technology Co., Ltd.</div>
      <div>地址：深圳市宝安区新安街道海滨社区N23区海天路15-1号卓越宝中时代广场一期A栋1109
      </div>
      <div>
        电话：13711598683 邮箱：eightcm2024@163.com
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.index-box {
  max-width: 1200px;
  margin: 0 auto;

  .head {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
  .content {
    text-align: left;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 50px;
    .logo-bg {
      position: absolute;
      left: 0;
      top: -20px;
      width: 600px;
      height: 500px;
      opacity: .5;
    }
    .title {
      font-size: 40px;
      font-weight: 600;
      color: #000;
      z-index: 99;
    }
    .desc {
      color: #000;
      font-size: 16px;
      margin-top: 30px;
      z-index: 99;
      position: relative;

    }
    .logo {
      width: 60%;
    }
  }
}
</style>